import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const HcpEmergingScienceWrapper = styled.div`
	&.br-hcp-emerging-wrapper {
		.hcpEmergingScienceWrapper {
			.c-family--story__inner {
				@media ${device.desktopsignup} {
					max-width: 1208px;
				}
				.o-header--h2 {
					@media ${device.laptopair2max} {
						font-weight: 900;
						margin-top: 2rem;
					}
				}
			}
			.emerging-science-gene-mods {
				.gatsby-image-wrapper {
					@media ${device.desktopsignup} {
						min-height: unset;
					}
				}
			}
			.faq-block {
				@media ${device.desktopsignup} and ${device.largeMaxDesktop} {
					padding-bottom: 8rem;
				}
				.genes-faq-media__right {
					.gatsby-image-wrapper {
						@media ${device.desktopsignup} and ${device.midDesktopMax} {
							min-height: 447px;
						}
						@media ${device.desktopStart} {
							min-height: 420px;
						}
					}
				}
				.genes-faq-content__left {

					@media ${device.laptopL} {
						width: 57%;
					}

					.faq-question {
						.faq-answer__faq-content {
							@media ${device.desktopsignup} {
								width: 91%;
							}
						}
						.faq-answer {
							.faq-answer__faq-content {
								@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
									padding: 0 0;
								}
							}
						}
					}
				}
				.genes-faq-media__right {
					@media ${device.desktopsignup} {
						height: fit-content;
					}
					@media ${device.desktopsignup} and ${device.laptopL} {
						width: 596px;
  					height: 420px;
					}

				}
			}
			.emerging-science-static {
				.o-container {
					&:nth-child(1) {
						@media ${device.desktopsignup} {
							max-width: 968px;
							padding: 0 0;
						}

						.step-info-box {
							&.o-column--full {
								@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
									margin-left: auto !important;
								}
								@media ${device.desktopsignup} {
									padding: 32px 70px 33px 55px;
								}
								.media {
									@media ${device.desktopsignup} and ${device.largeMaxDesktop} {
										width: 116px;
										height: 61.4px;
									}
									@media ${device.laptopMedium} {
										text-align: center;
										display: block;
										margin: auto;
									}

								}
								.o-paragraph {
									@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
										padding: 0 0;
										text-align: left;
									}
									.step-info-box {
										@media ${device.desktopsignup} {
											padding: 0;
										}
									}
								}
								.gatsby-image-wrapper {
									>div {
										&:nth-child(1) {
											@media ${device.laptopair2max} {
												display: none !important;
											}
										}
									}
									img {
										@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
											left: auto;
										}
									}
								}
							}
							img {
								@media ${device.desktopsignup} {
									width: 100%;
									position: absolute;
									left: 0;
								}
							}
						}
					}
				}
			}
			.containercliniwrap {
				.o-inner-wrapper-gens {
					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						max-width: 968px;
					}
				}
				.hcpretest-wrapper {
					@media ${device.desktopsignup} {
						max-width: 968px;
    				padding: 0px 0;
						width: 968px;
					}
					@media ${device.laptopair2max} {
						width: 100%;
					}

					.o-container {
						@media ${device.desktopsignup} {
							padding: 0 0;
						}
					}
					.o-genes-wrap {
						.info-box-2 {
							@media ${device.ipadLandscapemin} and  ${device.smalldeskmid} {
								min-width: auto;
							}
							@media ${device.desktopsignup} {
								padding: 32px 94px 30px;
								.info-box-2 {
									padding: 0;
								}
							}
							.retest-patient-block {
								.emerging-info-box {
									.o-paragraph {
										@media ${device.desktopsignup} {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
				.o-container{
					&.o-text-center {
						@media ${device.desktopsignup} {
							padding: 0 3rem;
						}
					}
				}

				.hcp-footer-wrap {
					@media ${device.desktopsignup} {
    				max-width: 968px;
    				padding: 0px 0;
					}
					.footer-block {
						@media ${device.mobileMedium} {
							margin-bottom: -85px;
						}
						@media ${device.tablet}{
							margin: 0 auto -15%;
						}
						@media ${device.ipadLandscapemin} {
							margin: 0 auto;
						}
						.footer-block--row {
							.footer-inner-block {
								box-shadow: 0 2px 2px rgb(17 17 17 / 16%);

								@media ${device.desktopsignup} {
									margin: 0 16px;
									padding: 25px 20px 120px;

									&:last-child {
										margin-right: 0;
									}
								}
								@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
									min-height: 620px;
								}

								@media ${device.desktopsignup} and ${device.midDesktopMax} {
									min-height: 540px;
								}
								@media ${device.largeDesktop} {
									min-height: 540px;
								}
								.o-common-title {
									p {
										strong {
											font-family: "Gotham", Arial, Helvetica, sans-serif;
											@media ${device.laptopair2max} {
												font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
											}
										}
									}
								}
								.o-button {
									a {

										@media ${device.desktopsignup} {
											font-size: 2.2rem;
										}
										@media ${device.mobileMedium} {
											margin: 32px auto 0 auto;
										}
										&:after {
											background-repeat: no-repeat;
											@media ${device.desktopsignup} {
												width: 26px !important;
												left: 0;
											}
										}
									}
								}
								.footer-inner-block__small-icon {
									img {
										@media ${device.laptopMedium} {
											padding: 16px 0;
										}
									}
								}

								&:nth-child(1) {
									@media ${device.desktopsignup} {
										margin-left: 0;
									}
									&:before {
										background: linear-gradient(90deg,#8000be 0%,#8000be 45%,#594dc6 100%);
									}
									.o-button {
										p {
											a {
												&:after {
													@media ${device.desktopsignup} and ${device.midDesktopMax} {
														width: 22px !important;
														left: 1px;
														padding-right: 10px;
													}
													@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
														width: 26px !important;
													}
												}
											}
										}
									}
								}
								&:nth-child(2) {
									&:before {
										background: linear-gradient(90deg,#3398ce 0%,#3398ce 25%,#17d0d4 74%,#06f2d7 100%);
									}
									@media ${device.laptopMedium} {
										margin: 0px auto 0 !important;
									}
									.o-button {
										p {
											@media ${device.smalldeskstrt} {
												padding-bottom: 0;
											}
										}
									}
									.footer-inner-block__small-icon {
										img {
											@media ${device.desktopsignup} and ${device.largeMaxDesktop} {
												width: 70px;
											}
											@media ${device.laptopMedium} {
												width: 68px;
											}
										}
									}
									.o-button {
										p {
											a {
												&:after {
													@media ${device.desktopsignup} and ${device.midDesktopMax} {
														width: 22px !important;
														left: 1px;
														padding-right: 7px;
													}
													@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
														width: 25px !important;
														right: 1px;
													}
												}
											}
										}
									}
								}
								.o-common-title {
									p {
										@media ${device.desktopsignup} {
											margin-bottom: 0;
											padding: 0 24px;
											font-family: 'Gotham', Arial, Helvetica, sans-serif;
											font-weight: bold;
										}
										@media ${device.desktopStart} {
											padding: 0 16px !important;
										}
									}
								}
								.o-button {
									p {
										a {
											align-items: center;
											max-width: 90%;
											margin: 32px auto 0 auto;

											@media ${device.laptopMedium} {
												max-width: 100%;
											}
										}
									}
								}
								.o-text--book {
									p {
										@media ${device.desktopsignup} {
											padding: 19px 0 20px 0;
											width: 100%;
										}
									}
								}
							}
						}
						@media ${device.desktopsignup} {
							padding-left: 0;
							padding-right: 0;
						}
					}
					.emerging-science--footer {
						.o-columns--two {
							&:last-child {
								.gtm-link-internal {
									p {
										@media ${device.desktopsignup} {
											padding-bottom: 0;
										}
									}
								}
							}
						}
					}
				}
			}
			.emerging-science-intro {
				@media ${device.desktopsignup} {
					padding-top: 12rem;
				}

        @media ${device.mobileMedium} {
          margin-top: 30px;
        }

				.o-paragraph {
					text-align: center;
					@media ${device.ipadLandscapemin} {
						width: 100%;
					}
					@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
						padding: 0 5rem;
						width: 90%;
					}
					span {

						@media ${device.minimumDeskEnd} {
							color: #000000;
						}
					}
				}

				.o-header {
					text-align: center;
					@media ${device.ipadLandscapemin} {
						margin-bottom: 4rem;
					}
					&.o-pt-20 {
						@media ${device.laptopair2max} {
							padding-top: 3rem;
						}
					}
					@media ${device.laptopMedium} {
						padding-top: 0;
					}
					@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
						padding-bottom: 2rem;
					}
					&.o-header--h2 {
						@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
							padding-bottom: 0;
						}
					}
				}
				.o-text-center {
					.o-text--book{
						@media ${device.desktopsignup} {
							font-weight: normal;
							letter-spacing: -0.22px;
						}
					}
				}

				.over-heading-style {
					width: 57%;
					margin: 0 auto;
					padding: 40px 0 50px 0;
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;
					text-align: center;

					@media ${device.laptopMedium} {
						width: 90%;
						padding: 0;
						margin-bottom: 24px;
						text-align: center;
						font-size: 2.5rem;
						line-height: 3rem;
					}

					@media ${device.desktopsignup} {
						width: 1023px;
						padding: 0;
						padding: 40px 0 50px 0;
						font-size: 42px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 5rem;
						letter-spacing: normal;
						text-align: center;
						color: #000;
					}
				}

				.emerging-science-static {
					.o-block--center__big {
						.o-paragraph {
							@media ${device.desktopsignup} {
								line-height: 3.4rem;
								width: 90%;
							}
						}
					}
				}
				.gene-therapy-trial {
					.o-container {
						@media ${device.laptopMedium} {
							padding: 0 0;
						}
					}
					.timeline {
						.fade-in-section {
							.jobdescription {
								@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
									width: 80% !important;
								}
							}

							.jobTitle {
								@media ${device.laptopair2max} {
									font-size: 2.7rem;
									font-weight: 500;
									line-height: 1.2;
								}
								p {
									@media ${device.laptopair2max} {
										font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
									}
								}
							}
							.jobdescription {
								@media ${device.ipadLandscapemin} {
									width: 100%;
								}
								p {
									@media ${device.ipadLandscapemin} {
										margin-bottom: 0;
										padding-bottom: 0;
									}
								}
							}
						}
					}

					.emerging-asterisk {
						p {
							@media ${device.desktopsignup} {
								width: 768px;
								margin: 40px 232px 120px 227px;
								font-family: 'Gotham', Arial, Helvetica, sans-serif;
								font-size: 18px;
								font-weight: normal;
								font-stretch: normal;
								font-style: normal;
								line-height: 2.2rem;
								letter-spacing: normal;
								text-align: center;
								color: #000;
								margin-left: auto;
								margin-right: auto;
							}
						}
					}
				}

				.evolution-genetic-test {
					@media ${device.desktopsignup} {
						margin: 12rem 0 0;
					}

					.emerging-science-story-inner {
						@media ${device.desktopsignup} {
							padding: 8.8rem 10rem 5.1rem 10rem;
							margin-bottom: 5rem;
						}

						&:before {
							@media ${device.desktopsignup} {
								height: 16px;
							}
						}
						.o-header--h2 {
							@media ${device.desktopsignup} {
								font-family: 'Gotham', Arial, Helvetica, sans-serif;
								letter-spacing: normal;
								text-align: center;
							}
						}
						.genetic-evolution {
							> p {
								&.o-paragraph {
									span {
										&.o-paragraph {
											@media ${device.desktopsignup} {
												letter-spacing: normal;
												text-align: center;
												color: #000;
											}
											span {
												color: #605dd4;
											}
										}
									}
								}
							}
							.evo-genetic-container {
								@media ${device.desktopsignup} {
									margin-bottom: 4.8rem;
								}

								& >p {
									text-align: center;
									margin: 8px 0px 40px;
									@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
										font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
										font-size: 18px;
										line-height: 22px;
										color: rgb(255, 255, 255);
									}

									@media ${device.laptopPros} {
										font-size: 16px;
    								line-height: 20px;
									}
									@media ${device.laptopMedium} {
										margin: 24px 0;
									}
									@media ${device.mobileMedium} {
										padding: 0 3rem;
									}
								}

								.desktop-image {

									@media ${device.laptopPros} {
										display: none;
									}
									@media ${device.tablet} and ${device.laptopMedium} {
										max-width: 100%;
									}
									img {
										@media ${device.tablet} and ${device.laptopMedium} {
											max-width: 100%;
											object-fit: contain !important;
										}
									}
								}
								.mobile-image {
									@media ${device.laptopPros} {
										display: block;
									}
									.gatsby-image-wrapper {
										@media${device.laptopMedium} {
											width: 90%;
										}
										@media ${device.laptopPros} {
											width: 100%;
										}
										@media ${device.tablet} and ${device.laptopPros} {
											width: 100%;
										}
									}
								}
								.o-header--h3 {
									&.o-text-center {
										color: #00ffd9;
										text-align: center;

										@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
											font-size: 3.4rem;
											line-height: 4.7rem;
											margin-top: 6rem;
										}
										@media ${device.laptopMedium} {
											margin: 20px 0 0 0;
											padding: 2rem 1rem 0;
										}
									}
								}
								.o-text-center {
									@media ${device.desktopsignup} {
										font-family: 'Gotham', Arial, Helvetica, sans-serif;
										width: 832px;
										margin: 88px 100px 32px 100px;
										font-size: 34px;
										font-weight: bold;
										font-stretch: normal;
										font-style: normal;
										line-height: normal;
										letter-spacing: normal;
										text-align: center;
										color: #00ffd9;
									}
								}
								> p {
									@media ${device.desktopsignup} {
										font-size: 18px;
										font-weight: normal;
										font-stretch: normal;
										font-style: normal;
										line-height: 22px;
										letter-spacing: normal;
										text-align: center;
										color: #fff;
										padding: 49px 105px 84px;
										margin-bottom: 0;
									}
									.line-graph-copy {
										font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
										font-size: 18px;
										line-height: 22px;
										color: #fff;
										@media ${device.desktopsignup} {
											font-size: 18px;
											font-weight: normal;
											font-stretch: normal;
											font-style: normal;
											line-height: 2.2rem;
											letter-spacing: normal;
											text-align: center;
											color: #fff;
											margin-bottom: 8.4rem;
										}
										@media ${device.laptopPros} {
											font-size: 16px;
											line-height: 20px;
										}

										.extlink {
											color: #00ffd9;
										}
									}
								}

								.desktop-image {
									.gatsby-image-wrapper {
										@media ${device.desktopsignup} {
											width: 100%;

											img {
												width: 883px;
												margin: auto;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

`

export const UnderwayTrailsContainerWrapper = styled.div`
	position: relative;
  margin: 5rem 0 15rem;

	@media ${device.laptopMedium} {
		margin: 0 0 101px;
	}

	&:before {
    content: "";
    display: block;
    background: #00FFD9;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 60px;

		@media ${device.laptopMedium} {
			height: 70%;
    	top: 115px;
		}
	}

	.o-container {
		.step-info-box {
			display: flex;
			flex-wrap: inherit !important;
			max-width: 1020px;
			margin: 120px auto 0;
			width: 100%;
			align-items: center;
			background-color: #300064;
			padding: 40px 100px;
			position: relative;

			@media ${device.laptopMedium} {
				margin-top: 30px;
    		display: block;
				margin: 50px auto 0;
				padding: 10px 10px 30px;
				justify-content: center;
			}

			img {
				width: 100px;
				position: relative;
				left: 5px;
				max-width: 100%;

				@media ${device.laptopMedium} {
					width: 40px;
					left: unset;
					margin: 24px auto;
					display: block;
				}
			}

			.o-paragraph {
				p {
					width: 90%;
    			margin: auto;
					text-align: left;
					line-height: 30px;
					color: #fff;
					font-size: 2.2rem;
					font-weight: 500;

					@media ${device.laptopMedium} {
						text-align: center;
						line-height: 2rem;
						padding: 0 30px;
						width: 100%;
						font-size: 1.8rem;
					}

					a {
						position: relative;
						text-decoration: underline;
						text-underline-offset: 3px;
						cursor: pointer;
						color: #fff;

						&:after {
							position: relative;
							top: 3px;
							margin-left: 10px;
						}

						@media ${device.laptopMedium} {
							padding-top: 10px;
							display:block;
						}

					}
				}
			}
		}
	}
`

export const CalloutWithHeaderContainer = styled.div`
	padding-top: 88px;

	@media ${device.laptopMedium} {
		padding-top: 30px;
	}
`

export const CalloutWithHeaderWrapper = styled.div`
	.o-text-center {
		text-align: center;

		@media ${device.laptopMedium} {
			font-size: 2rem;
			line-height: 2.6rem;
		}

		.o-header--h2 {
			p	{
				font-size: 4.2rem;
				font-weight: normal;
				line-height: 5rem;
				color: #000000;
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
				padding: 0 0 20px;
				margin: 0;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}
		}

		.o-text--book {
			display: block;

			p {
				width: 80%;
				margin: auto;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

				em {
					font-style: italic;
				}
			}

			&.pb-5 {
				&:last-child {
					padding-bottom: 0 !important;
				}
			}
		}
	}
`

export const GeneTestEvolutionContainerWrapper = styled.div`
	margin: 10rem 0 5rem;
	position: relative;

	@media ${device.laptopMedium} {
		margin: 2rem 0 1rem;
		margin-bottom: 48px !important;
	}

	&:before {
    content: "";
    background: #00FFD9;
    display: block;
    width: 100%;
    height: 70%;
    position: absolute;
    top: 15%;

		@media ${device.laptopMedium} {
			top: 5%;
			height: 92%;
		}
	}

	.o-container {
		.c-family--story__inner {
			background-color: #f7f7f7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			position: relative;
			padding: 10rem 9rem;
			color: #000000;

			@media ${device.laptopMedium} {
				padding: 2rem 3rem;
				font-size: 2rem;
    		line-height: 2.6rem;
				margin-top: 48px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				width: 100%;
				height: 10px;
				position: absolute;
				top: 0;
				left: 0;
				height: 10px;
			}

			.o-header--h2 {
				p {
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;
					color: #000000;
					margin: 0 0 40px;
					font-family: 'Gotham', Arial, Helvetica, sans-serif;
					text-align: center;

					@media ${device.laptopMedium} {
						margin: 20px 0 0 0;
						text-align: center;
						font-size: 2.5rem;
    				line-height: 3rem;
					}
				}
			}

			.genetic-evolution {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				position: relative;

				@media ${device.laptopMedium} {
					margin-top: 32px;
				}

				.evo-genetic-container {
					background-color: #000000;
    			margin-bottom: 50px;

					.o-header--h3 {
						p {
							font-size: 3.4rem;
    					line-height: 4.7rem;
							text-align: center;
							color: #00FFD9;
							margin-top: 60px;
							padding: 0 150px;
							font-weight: 600;
							font-family: 'Gotham', Arial, Helvetica, sans-serif;

							@media ${device.laptopMedium} {
								font-size: 2.5rem;
    						line-height: 3rem;
								margin: 20px 0 0 0;
								padding: 2rem 1rem 0;
								background-color: #000000;
    						width: 100%;
							}
						}
					}

					img {
						@media ${device.laptopMedium} {
							width: 100%;
							padding: 1rem 0 0;
							margin-bottom: 32px;
						}

						.desktop-image {
							display: block;

							@media ${device.laptopPros} {
								display: none;
							}
						}

						.mobi-image {
							display: none;

							@media ${device.laptopPros} {
								display: block;
							}
						}
					}

					.line-graph-copy {
						p {
							margin: 8px 0 40px;
							font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
							font-size: 18px;
							line-height: 22px;
							text-align: center;
							color: #fff;
							padding: 0 65px 80px;

							@media ${device.laptopMedium} {
								margin: 24px 0;
							}

							@media ${device.laptopPros} {
								font-size: 16px;
    						line-height: 20px;
							}

							@media ${device.mobileMedium} {
								padding: 0 3rem !important;
							}

							a {
								color: #00FFD9;
								cursor: pointer;
							}
						}
					}
				}

				.o-paragraph {
					p {
						width: 80%;
						margin: auto;
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.mobileMedium} {
							width: 100%;
							padding: 0 1rem;
							font-size: 1.8rem;
							line-height: 2.6rem;
						}

						b {
							color: #8000BE;
							font-weight: 500 !important;
						}
					}
				}
			}
		}
	}
`
