export const hcpIIR: any = {
  hcpMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
  hcpFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
  hcpCarousel: "",
  hcpBanner: "Banner CLINICAL TRIALS IN INHERITED RETINAL DISEASES",
  hcpBannerHTML: "Banner - HTML - ES - HCP - Brazil (PT)",
  hcpFooter: "Footer - HCP - Retina Brazil (PT)",
  hcpExitPopup: "Exit Popup HCP Brazil (PT)",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
  hcpSiteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  hcpFootRef: "Clinical Trials HCP - References - Retina Brazil (PT)",
  hcpNavCalloutPara: "Emerging science Footer Callout HTML - Emerging Science - HCP Retina Brazil (PT)",
  hcpTestOrRetestPara: "Test or Retest Patient Section HTML - Emerging Science - HCP Retina Brazil (PT)",
  hcpAnOpporPara: "Clinical Trials - An opportunity to contribute to new studies HTML - Canada",
  overThrity: "Emerging science over 30 clinical trials - Retina Brazil (PT)",
  sciAdv: "Science is advancing HTML - Emerging Science - HCP Retina Brazil (PT)",
  hcpEvolutionGTPara: "Evolution of genetic testing HTML - Emerging Science - HCP Retina Brazil (PT)",
  timeLine: "DNA Timeline Emerging science HCP HTML - Retina Brazil (PT)",
  latestUnderway: "Latest Underway Trial HTML - Emerging Science - HCP Retina Brazil (PT)",
  breakthrough: "Clinical Trial breakthrough science - Retina Brazil (PT)",
  backToTopGTM: "back to top - emerging science - hcp",
  footerClassName: "investigating-irds-footer"
}
